/**
 * servings.js
 */

(function ($) {
    $(document).ready(function () {
        if ($('#serving-counter').length !== 0) {
            $('.buttons button').removeAttr('disabled');
        }
        $('#serving-counter').on('click', '.alcohol .plus, .alcohol .minus', function () {
            handleServingClick.call(this, $(this).hasClass('plus') ? 1 : -0.5);
        });

        function handleServingClick(increment) {
            let amount = $(this).parent().parent().find('.amount');
            let value = parseFloat(amount.text());
            let serving = $(this).parent().parent().data('serving');
            if (!value) {
                value = 0;
            }
            value += increment;
            if (value < 0) {
                value = 0;
            }
            amount.text(value.toFixed(1))
            $(this).parent().parent().data('totalServing', value * serving);
            calculateAllDrinkServings();
        }
        calculateAllDrinkServings();
    });

    $(document).on('input', '.custom-percent, .size', function () {
        calculateAllDrinkServings();
    });

    $('#serving-counter #duplicate').on('click', function () {
        let customAlcohol = $('.custom').first();
        let newCustomAlcohol = customAlcohol.clone();
        newCustomAlcohol.find('.amount').text(0);
        $('.alcohols').children('.custom').last().after(newCustomAlcohol);
    });

    function calculateAllDrinkServings() {
        let allServings = 0;
        $('.alcohol.custom').each(function() {
            $(this).attr('data-percentage', parseFloat($(this).find('.custom-percent').val()));
        });

        $('.alcohol').each(function() {
            let $alcoholElement = $(this);
            let percent = parseFloat($alcoholElement.attr('data-percentage'));
            let size = parseFloat($alcoholElement.find('select.size').val());
            let sizeInLiters = size / 100;
            let pureAlcoholInGrams = sizeInLiters * (percent / 100) * 789; // 789g/L is the density of ethanol
            let servings = pureAlcoholInGrams / 12;
            $alcoholElement.attr('data-serving', servings.toFixed(2));
            let calories = pureAlcoholInGrams * 7;
            $alcoholElement.attr('data-calories', calories.toFixed(2));
            $alcoholElement.attr('data-size', size);
            let totalServings = servings * $(this).find('.amount').text();
            allServings += totalServings;
        });
        $('#total-servings').text(allServings.toFixed(1));
    }



})(jQuery);
